export var GOOD_TABLE_OPTIONS = function (option) {
    option = option ?? {};

    return {
        isLoading: false,
        columns: [
            {label: 'No', field: 'no', sortable: false},
            {label: 'Id', field: 'karuteNo',},
            {label: '氏名', field: 'name',},
            {label: 'Address', field: 'address',},
            {label: '年齢', field: 'age', type: 'number',},
            {label: '性別', field: 'gender'},
            {label: '血圧', field: 'bl', type: "number"},
            {label: '心拍数', field: 'pu', type: "number"},
            {label: '酸素飽和度', field: 'ox', type: "number"}
        ],
        rows: [],
        totalRecords: 0,
        selectOptions: {enabled: option.selectOptions_enabled ?? false,selectOnCheckboxOnly: option.selectOptions_enabled ?? false
        ,disableSelectInfo: true,
        },
        pagingOption: {
            enabled: true,
            mode: 'records',
            position: 'top',
            dropdownAllowAll: false,
            nextLabel: '',
            prevLabel: '',
            pageLabel: '',
            ofLabel: '/',
            perPageDropdown: [10,25, 50, 100, 200],
            perPage: 25,
            rowsPerPageLabel: "表示数"
        },
        searchOptions: {enabled: true, skipDiacritics: true,  placeholder: option.placeholder ?? 'キーワード入力後Enter'},
        pagingParams: {keyword: '', columnFilters: {}, sort: [], currPage: 1, perPage: 25},
        fiexedHeader: true
    };
};
export var GOOD_FUNCTIONS = function () {
    return {
        onGoodRowClick: function (data) {
            console.log("GOOD_FUNCTIONS====== rowClick", data);
        }, onGoodSearch: function (data) {
            console.log("GOOD_FUNCTIONS====== onGoodSearch", JSON.stringify(data));
            this.pagingParams.keyword = data.searchTerm;
            //キーワードサーチのドリガーを封印する
            //this.goodPaging();
        }, goodUpdateParams(newProps) {
            console.log("GOOD_FUNCTIONS====== updateParams", newProps);
            this.pagingParams = Object.assign({}, this.pagingParams, newProps);
        }, onGoodPageChange(params) {
            console.log("GOOD_FUNCTIONS====== onPageChange", params);
            this.goodUpdateParams({currPage: params.currentPage});
            this.goodPaging(true);
        }, onGoodPerPageChange(params) {
            console.log("GOOD_FUNCTIONS====== onPerPageChange", params);
            this.goodUpdateParams({perPage: params.currentPerPage});
            this.goodPaging();
        }, onGoodSortChange(params) {
            console.log("GOOD_FUNCTIONS====== onGoodSortChange", params);
            this.goodUpdateParams({
                sort: params.map(function (param) {
                    return {field: param.field, sort: param.type}
                })
            });
            this.goodPaging();
        }, onGoodColumnFilter(params) {
            console.log("GOOD_FUNCTIONS====== onColumnFilter 実は使わない", params);
            this.goodUpdateParams(params);
            this.goodPaging();
        },findColumnIndex(columns,fieldName){
            return columns.findIndex(function(col){
                return col.field == fieldName;
            });
        },isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
    }
};

export var GOOD_MOUNT_FUNCTION = function (vm) {
    document.querySelector('.magnifying-glass').addEventListener('click', function () {
        vm.goodPaging();//虫眼鏡を押された
    });
    document.querySelector("input.vgt-input.vgt-pull-left").addEventListener('keyup', function (event) {
        vm.pagingParams.keyword = event.target.value;//検索欄に入力下
    });
}


// export var COMMON_FUN = function () {
//     return {
//         FIND_COLUMN_INDEX:function(columns,fieldName){
//             return columns.findIndex(function(col){
//                 return col.field == fieldName;
//             });
//         }
//     };
// }